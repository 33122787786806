import { LitElement, html, HTMLTemplateResult } from "lit";
import { property } from "lit/decorators.js";

import { when } from "lit/directives/when.js";

import type { Constructor } from "@/internals/util-types";
import { OverlayPlacement, OverlayTrigger } from "@/components/display/overlay";

import "@/components/display/atlas-tooltip/atlas-tooltip";

export type WithTooltipProps = {
    "tooltip": string;
    "tooltip-placement": OverlayPlacement;
    "tooltip-trigger": OverlayTrigger;
};

export declare class WithTooltipInterface {
    tooltip: string;

    tooltipPlacement: string;

    tooltipTrigger: string;

    hasTooltip(): boolean;

    renderTooltip(id: string): HTMLTemplateResult;
}

export const WithTooltipMixin = <T extends Constructor<LitElement>>(superClass: T) => {
    /**
     * @dependency atlas-tooltip
     *
     * @prop {string} tooltip - O texto do tooltip
     * @prop {OverlayPlacement} tooltip-placement - A posição em relação ao elemento que o tooltip será exibido
     * @prop {OverlayTrigger} tooltip-trigger - O gatilho que irá acionar o tooltip
     */
    class WithTooltipClass extends superClass {
        @property({ type: String, reflect: true }) tooltip: string;

        @property({ type: String, attribute: "tooltip-placement", reflect: true }) tooltipPlacement: OverlayPlacement =
            "bottom";

        @property({ type: String, attribute: "tooltip-trigger", reflect: true }) tooltipTrigger: OverlayTrigger =
            "hover focus";

        hasTooltip(): boolean {
            return !!this.tooltip;
        }

        renderTooltip(id: string): HTMLTemplateResult {
            return when(
                this.hasTooltip(),
                () => html`
                    <atlas-tooltip id=${id} placement=${this.tooltipPlacement} trigger=${this.tooltipTrigger}>
                        ${this.tooltip}
                    </atlas-tooltip>
                `
            );
        }
    }

    return WithTooltipClass as Constructor<WithTooltipInterface> & T;
};
