import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { styleMap } from "lit/directives/style-map.js";
import { when } from "lit/directives/when.js";

import DeviceController from "@/controllers/device-controller";

import { emit } from "@/internals/events";
import { WithBadgeMixin, type WithBadgeProps } from "@/internals/mixins/with-badge-mixin";
import { WithCollapseMixin, type WithCollapseProps } from "@/internals/mixins/with-collapse-mixin";
import { WithDescriptionMixin, type WithDescriptionProps } from "@/internals/mixins/with-description-mixin";
import { WithVisibilityIconMixin, type WithVisiblityIconProps } from "@/internals/mixins/with-visibility-icon-mixin";
import { isEmptySlot } from "@/internals/slot";

import { Watch } from "@/decorators/watch";

import AtlasElement from "@/components/atlas-element";

import type { AtlasElementProps } from "@/components/atlas-element";
import type AtlasCarousel from "@/components/layout/atlas-carousel/atlas-carousel";
import type AtlasToolbar from "@/components/layout/atlas-toolbar/atlas-toolbar";
import type AtlasVisibilityIconButton from "@/components/display/atlas-visibility-icon-button/atlas-visibility-icon-button";

import "@/components/display/atlas-badge/atlas-badge";
import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-text/atlas-text";
import "@/components/layout/atlas-carousel/atlas-carousel";
import "@/components/layout/atlas-layout/atlas-layout";
import "@/components/layout/atlas-toolbar/atlas-toolbar";

import styles from "./atlas-panel.scss";

const ComposedClass = WithDescriptionMixin(WithVisibilityIconMixin(WithBadgeMixin(WithCollapseMixin(AtlasElement))));

export type PanelProps = AtlasElementProps &
    WithCollapseProps &
    WithBadgeProps &
    WithVisiblityIconProps &
    WithDescriptionProps & {
        "header": string;
        "carousel": boolean;
        "items-per-page": number;
        "fluid": boolean;
        "height": string;
    };

/**
 * @dependency atlas-badge
 * @dependency atlas-heading
 * @dependency atlas-text
 * @dependency atlas-toolbar
 * @dependency atlas-carousel
 * @dependency atlas-layout
 *
 * @tag atlas-panel
 */
@customElement("atlas-panel")
export default class AtlasPanel extends ComposedClass {
    static styles = styles;

    /** Título do painel */
    @property({ type: String }) header: string;

    /**  Indica se o painel vai ser exibido como um carrossel */
    @property({ type: Boolean }) carousel: boolean;

    /** Número de itens que vão aparecer em uma página do carrossel */
    @property({ type: Number, attribute: "items-per-page" }) itemsPerPage: number = 1;

    /** Indica se o painel deve ser fluido (se encaixar inteiramente no container pai) */
    @property({ type: Boolean }) fluid: boolean;

    /** Altura do painel */
    @property({ type: String }) height: string = "auto";

    @state() private _hasSlottedActions: boolean = false;

    @state() private _hasSlottedToolbar: boolean = false;

    public carouselElement: AtlasCarousel;

    private _deviceController: DeviceController = new DeviceController(this);

    /** @internal */
    public connectedCallback() {
        super.connectedCallback?.();

        this.emitPanelEvent = this.emitPanelEvent.bind(this);

        this._isVisibilityIconButton = false;

        if (this.collapsible) {
            this.collapsed = !this.expanded;
        }

        if (this.panelHasHeight()) {
            this.fluid = false;
        }

        this.updateComplete.then(() => {
            this.addEventListener("atlas-collapse-button-click", this.emitPanelEvent);

            if (this.carousel) {
                this.carouselElement = this.shadowRoot.querySelector("atlas-carousel") as AtlasCarousel;
            }

            if (this.hasContent()) {
                this.shadowRoot.querySelector(".panel").classList.add("has-content");
            }
        });
    }

    /** @internal */
    public disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-collapse-button-click", this.emitPanelEvent);
    }

    @Watch("hasVisibilityIcon", true)
    onVisibilityIconChange() {
        const slottedToolbar = this.getSlottedToolbar();

        if (!slottedToolbar) return;

        this._hasSlottedToolbar = true;

        slottedToolbar.toggleAttribute("has-visibility-icon", Boolean(this.hasVisibilityIcon));
    }

    /**
     * Retorna a referência do ícone de visibilidade
     * @returns {AtlasVisibilityIconButton} - A referência do ícone de visibilidade
     */
    public getVisibilityIconButton(): AtlasVisibilityIconButton {
        if (!this.shadowRoot) return null;

        const toolbar = this.getSlottedToolbar() || this.shadowRoot.querySelector("atlas-toolbar");

        if (!toolbar || !toolbar.hasVisibilityIcon) return null;

        return toolbar.shadowRoot.querySelector("atlas-visibility-icon-button");
    }

    private getSlottedToolbar() {
        const slot = this.shadowRoot.querySelector("slot:not([name])") as HTMLSlotElement;
        return slot.assignedElements().find((element) => element.tagName === "ATLAS-TOOLBAR") as AtlasToolbar;
    }

    private panelHasHeight() {
        return this.height !== "auto";
    }

    private emitPanelEvent() {
        emit(this, `atlas-panel-${this.collapsed ? "collapse" : "expand"}`);
    }

    private onActionsSlotChange() {
        const actionsSlot = this.shadowRoot.querySelector("slot[name=actions]") as HTMLSlotElement;

        this._hasSlottedActions = actionsSlot.assignedElements().length > 0;
    }

    private isHeaderVisible() {
        return (
            this.header ||
            (this.collapsible && !this._deviceController.isMobile) ||
            this._hasSlottedActions ||
            this.hasVisibilityIcon
        );
    }

    private hasContent() {
        if (this.carousel) {
            return !isEmptySlot(this.carouselElement);
        }

        return !isEmptySlot(this);
    }

    private renderContent() {
        if (this.carousel) {
            return html`
                <atlas-carousel items-per-page=${this.itemsPerPage}>
                    <slot @slotchange=${this.onVisibilityIconChange}></slot>
                </atlas-carousel>
            `;
        }

        return html` <slot @slotchange=${this.onVisibilityIconChange}></slot> `;
    }

    private renderActionsToolbar() {
        return html`
            <atlas-toolbar only-actions ?has-visibility-icon=${this.hasVisibilityIcon}>
                <slot slot="actions" name="actions" @slotchange=${this.onActionsSlotChange}></slot>
            </atlas-toolbar>
        `;
    }

    private renderHeader() {
        const showCollapseButton = this.collapsible && !this._deviceController.isMobile;
        const showMobileCollapseButton = this.collapsible && this._deviceController.isMobile;

        return html`
            <div class="panel-header">
                <div class="panel-title">
                    <atlas-layout gap="4" alignment="center" inline>
                        <div class="title-with-button">
                            <atlas-heading size="h5">${this.header}</atlas-heading>
                            ${when(showMobileCollapseButton, () => this.renderCollapseButton())}
                        </div>
                        ${this.renderBadge()}
                    </atlas-layout>
                </div>
                ${this.renderActionsToolbar()} ${when(showCollapseButton, () => this.renderCollapseButton())}
                ${this.renderDescription({ wrapperClass: "panel-description" })}
            </div>
        `;
    }

    private renderBody() {
        const panelBodyClass = {
            "panel-body": true,
            "fluid": this.fluid
        };

        const bodyHtml = html` <div class="${classMap(panelBodyClass)}">${this.renderContent()}</div> `;

        return when(
            this.collapsible,
            () => this.renderContentWithCollapse(bodyHtml),
            () => bodyHtml
        );
    }

    /** @internal */
    public render() {
        const panelClass = {
            "panel": true,
            "collapsible": this.collapsible,
            "collapsed": this.collapsible && this.collapsed,
            "hide-header": !this.isHeaderVisible(),
            "has-toolbar": !this._hasSlottedToolbar && (this._hasSlottedActions || this.hasVisibilityIcon)
        };

        const panelBodyWrapperClass = {
            "panel-body-wrapper": true,
            "collapsed": this.collapsible && this.collapsed,
            "fluid": this.fluid,
            "has-height": this.panelHasHeight()
        };

        return html`
            <div class=${classMap(panelClass)} style=${styleMap({ height: this.height })}>
                ${this.renderHeader()}
                <div class=${classMap(panelBodyWrapperClass)}>${this.renderBody()}</div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-panel": AtlasPanel;
    }
}
