import { LitElement, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import { Watch } from "@/decorators/watch";
import BaseController from "@/internals/base-controller";
import { emit } from "@/internals/events";
import { Theme } from "@/internals/theme";

import styles from "./atlas-wizard-step.scss";

/**
 * @prop {string} name - Nome do passo
 * @prop {string} header - Título do passo
 * @prop {boolean} active - Indica se o passo está ativo, isso é, se ele que deve ser mostrado na primeira visualização do wizard
 * @prop {boolean} disabled - Indica se o passo esta desabilitado
 * @prop {boolean} disable-validations - Desabilita as validações automáticas do passo
 * @prop {boolean} disable-return - Desabilita a ação de voltar do passo
 * @prop {string} next-button-label - Texto do botão de "Avançar" do passo
 * @prop {Theme} next-button-theme - Tema do botão de "Avançar" da passo
 * @prop {boolean} centralized-content - Indica se o conteúdo do passo está centralizado
 * @prop {boolean} hide-buttons - Indica se os botões do rodapé devem estar ocultos no passo
 *
 * @slot - Usado para incluir o conteúdo do passo
 *
 * @event {CustomEvent} atlas-wizard-step-go - Evento disparado quando é solicitado para ir para o passo, através do método "goToStep"
 * @event {CustomEvent} atlas-wizard-step-changed - Evento disparado quando o estado do passo é alterado
 *
 * @tag atlas-wizard-step
 */
@customElement("atlas-wizard-step")
export default class AtlasWizardStep extends LitElement {
    static styles = styles;

    @property({ type: String }) name: string;

    @property({ type: String }) header: string;

    @property({ type: Boolean, reflect: true }) active: boolean = false;

    @property({ type: Boolean, reflect: true }) disabled: boolean = false;

    @property({ type: Boolean, reflect: true, attribute: "disable-validations" }) disableValidations: boolean = false;

    @property({ type: Boolean, attribute: "disable-return" }) disableReturn: boolean = false;

    @property({ type: String, attribute: "next-button-label" }) nextButtonLabel: string;

    @property({ type: String, attribute: "next-button-theme" }) nextButtonTheme: Theme = "primary";

    @property({ type: Boolean, attribute: "centralized-content" }) centralizedContent: boolean = false;

    @property({ type: Boolean, attribute: "hide-buttons" }) hideButtons: boolean = false;

    @state() private _visible = false;

    @state() private _stepController: BaseController;

    protected _offStep?: boolean;

    constructor() {
        super();

        this.goToStep = this.goToStep.bind(this);
    }

    getStepCustomController() {
        return this._stepController.customController;
    }

    setStepController(stepController: BaseController) {
        this._stepController = stepController;
    }

    setState(key: string | { [key: string]: any }, value?: any) {
        this._stepController?.setState(key, value);
    }

    getState() {
        return this._stepController?.getState();
    }

    clearState(key: string | string[]) {
        this._stepController?.clearState(key);
    }

    toggleStep(show: boolean) {
        this._visible = show;
        this.toggleAttribute("visible", this._visible);
    }

    goToStep() {
        emit(this, "atlas-wizard-step-go", { trackDisable: true });
    }

    @Watch("disabled", true)
    onChangeDisabled() {
        emit(this, "atlas-wizard-step-changed", { trackDisable: true });
    }

    isOffStep() {
        return this._offStep;
    }

    render() {
        const stepClass = {
            "step-content": true,
            "off-step": this._offStep
        };

        return html`
            <div class="${classMap(stepClass)}">
                <slot></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-wizard-step": AtlasWizardStep;
    }
}
