import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import { getAssetPath } from "@/helpers/base-path";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import { ExpandedSize } from "@/internals/basic-types";

import styles from "./atlas-illustration.scss";

export type IllustrationProps = AtlasElementProps & {
    "name": string;
    "alt": string;
    "size": ExpandedSize;
    "scale-vertically": boolean;
};

/**
 * @prop {string} name - Nome da ilustração
 * @prop {string} alt - Descrição da ilustração (Para tecnologias assistivas)
 * @prop {ExpandedSize} size - Tamanho da ilustração
 * @prop {string} scale-vertically - Define se a ilustração deve expandir conforme a altura do elemento pai
 *
 * @tag atlas-illustration
 */
@customElement("atlas-illustration")
export default class AtlasIllustration extends AtlasElement {
    static styles = styles;

    @property({ type: String }) name: string;

    @property({ type: String }) size: ExpandedSize = "md";

    @property({ type: String }) alt: string;

    @property({ type: Boolean, attribute: "scale-vertically" }) scaleVertically: boolean;

    getIllustrationPath() {
        return getAssetPath(`assets/illustrations/${this.name}.svg`);
    }

    render() {
        const illustrationClass = {
            "atlas-illustration": true,
            "scale-vertically": this.scaleVertically,
            [`${this.size}`]: !!this.size,
            "skeleton": this.skeletonLoading
        };

        return html`
            <div class=${classMap(illustrationClass)}>
                <img src="${this.getIllustrationPath()}" alt="${this.alt}" />
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-illustration": AtlasIllustration;
    }
}
