import {css} from "lit-element/lit-element.js";
export default css`:host {
  display: none;
  width: 100%;
}

:host([visible]) {
  display: inline-flex;
}

.step-content {
  display: block;
  width: 100%;
}
.step-content.off-step {
  margin-top: 0.5rem;
}

::slotted(atlas-section:last-child) {
  margin-bottom: 0;
}

@media screen and (min-width: 992px) {
  .step-content.off-step {
    margin-top: 2rem;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2NvZGVidWlsZC9vdXRwdXQvc3JjMTQwNjkxNzc4MS9zcmMvcGFja2FnZXMvd2ViY29tcG9uZW50cy9zcmMvY29tcG9uZW50cy93aXphcmQvYXRsYXMtd2l6YXJkLXN0ZXAiLCJzb3VyY2VzIjpbImF0bGFzLXdpemFyZC1zdGVwLnNjc3MiLCIuLi8uLi8uLi9zdHlsZXMvbWl4aW5zL19icmVha3BvaW50cy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0k7RUFDQTs7O0FBR0o7RUFDSTs7O0FBR0o7RUFDSTtFQUNBOztBQUVBO0VBQ0k7OztBQUlSO0VBQ0k7OztBQ1JBO0VEWUE7SUFDSSIsInNvdXJjZXNDb250ZW50IjpbIkB1c2UgXCIuLi8uLi8uLi9zdHlsZXMvbWl4aW5zL2JyZWFrcG9pbnRzXCI7XG5cbjpob3N0IHtcbiAgICBkaXNwbGF5OiBub25lO1xuICAgIHdpZHRoOiAxMDAlO1xufVxuXG46aG9zdChbdmlzaWJsZV0pIHtcbiAgICBkaXNwbGF5OiBpbmxpbmUtZmxleDtcbn1cblxuLnN0ZXAtY29udGVudCB7XG4gICAgZGlzcGxheTogYmxvY2s7XG4gICAgd2lkdGg6IDEwMCU7XG5cbiAgICAmLm9mZi1zdGVwIHtcbiAgICAgICAgbWFyZ2luLXRvcDogMC41cmVtO1xuICAgIH1cbn1cblxuOjpzbG90dGVkKGF0bGFzLXNlY3Rpb246bGFzdC1jaGlsZCkge1xuICAgIG1hcmdpbi1ib3R0b206IDA7XG59XG5cbkBpbmNsdWRlIGJyZWFrcG9pbnRzLmJyZWFrcG9pbnQtdXAtbGcge1xuICAgIC5zdGVwLWNvbnRlbnQub2ZmLXN0ZXAge1xuICAgICAgICBtYXJnaW4tdG9wOiAycmVtO1xuICAgIH1cbn1cbiIsIkBtaXhpbiBicmVha3BvaW50LXVwLXNtIHtcbiAgICBAbWVkaWEgc2NyZWVuIGFuZCAobWluLXdpZHRoOiA1NzZweCkge1xuICAgICAgICBAY29udGVudDtcbiAgICB9XG59XG5cbkBtaXhpbiBicmVha3BvaW50LXVwLW1kIHtcbiAgICBAbWVkaWEgc2NyZWVuIGFuZCAobWluLXdpZHRoOiA3NjhweCkge1xuICAgICAgICBAY29udGVudDtcbiAgICB9XG59XG5cbkBtaXhpbiBicmVha3BvaW50LXVwLWxnIHtcbiAgICBAbWVkaWEgc2NyZWVuIGFuZCAobWluLXdpZHRoOiA5OTJweCkge1xuICAgICAgICBAY29udGVudDtcbiAgICB9XG59XG5cbkBtaXhpbiBicmVha3BvaW50LXVwLXhsIHtcbiAgICBAbWVkaWEgc2NyZWVuIGFuZCAobWluLXdpZHRoOiAxMjAwcHgpIHtcbiAgICAgICAgQGNvbnRlbnQ7XG4gICAgfVxufVxuXG5AbWl4aW4gYnJlYWtwb2ludC11cC14eGwge1xuICAgIEBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDE0MDBweCkge1xuICAgICAgICBAY29udGVudDtcbiAgICB9XG59XG5cbkBtaXhpbiBicmVha3BvaW50LWRvd24tbGcge1xuICAgIEBtZWRpYSBzY3JlZW4gYW5kIChtYXgtd2lkdGg6IDk5MnB4KSB7XG4gICAgICAgIEBjb250ZW50O1xuICAgIH1cbn1cbiJdfQ== */`;
