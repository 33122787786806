import { removeNonNumeric } from "@/helpers/formatters";
import { buildPathToApi, MAPPED_ENDPOINTS } from "./api-helpers";
import { get } from "@/helpers/request";

export type PostalCode = {
    postalCode: string;
    address: string;
    province: string;
    cityId: number;
    cityName: string;
    state: string;
    isGeneral: boolean;
};

export type PostalCodeApiResponse = {
    success: boolean;
    message?: string;
    postalCode?: PostalCode | object;
};

export function getPostalCodeData(postalCode: string) {
    const postalCodeValue = removeNonNumeric(postalCode);
    const apiPath = buildPathToApi(MAPPED_ENDPOINTS.postalCodeValidate);

    return get(apiPath, { postalCode: postalCodeValue }) as Promise<PostalCodeApiResponse>;
}
