import { LitElement, html, HTMLTemplateResult } from "lit";
import { property, state } from "lit/decorators.js";

import { when } from "lit/directives/when.js";

import type { Constructor } from "@/internals/util-types";

import { type IconButtonSize } from "@/components/display/atlas-icon-button/types";

import "@/components/display/atlas-visibility-icon-button/atlas-visibility-icon-button";

export type WithVisiblityIconProps = {
    "has-visibility-icon": boolean;
    "visibility-icon-size": IconButtonSize;
    "is-data-visible": boolean;
};

export declare class WithVisibilityIconInterface {
    hasVisibilityIcon: boolean;

    visibilityIconSize: IconButtonSize;

    isDataVisible: boolean;

    _isVisibilityIconButton: boolean;

    renderVisibilityIcon(): HTMLTemplateResult;
}

export const WithVisibilityIconMixin = <T extends Constructor<LitElement>>(superClass: T) => {
    /**
     * @dependency atlas-visibility-icon-button
     */
    class WithVisibilityIconClass extends superClass {
        /** Indica se o componente possui um ícone de visibilidade de dados */
        @property({ type: Boolean, attribute: "has-visibility-icon", reflect: true }) hasVisibilityIcon: boolean;

        /** Tamanho do ícone de visibilidade */
        @property({ type: String, attribute: "visibility-icon-size" }) visibilityIconSize: IconButtonSize = "2x";

        /** Indica se os dados estão visíveis */
        @property({ type: Boolean, attribute: "is-data-visible", reflect: true }) isDataVisible: boolean = false;

        @state() _isVisibilityIconButton: boolean = true;

        onClickVisibilityIcon(event: CustomEvent) {
            this.isDataVisible = event.detail.isDataVisible;
        }

        public renderVisibilityIcon(): HTMLTemplateResult {
            return when(
                this.hasVisibilityIcon,
                () => html`
                    <atlas-visibility-icon-button
                        icon-size="${this.visibilityIconSize}"
                        ?is-data-visible=${this.isDataVisible}
                        ?is-icon-button=${this._isVisibilityIconButton}
                        @atlas-visibility-icon-click="${this.onClickVisibilityIcon}"
                    ></atlas-visibility-icon-button>
                `
            );
        }
    }

    return WithVisibilityIconClass as Constructor<WithVisibilityIconInterface> & T;
};
