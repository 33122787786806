import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { WithPopoverMixin } from "@/internals/mixins/with-popover-mixin";

import styles from "./atlas-summary-item.scss";
import "@/components/display/atlas-icon-button/atlas-icon-button";

/**
 * @dependency atlas-icon-button
 *
 * @prop {string} label - Texto da label
 * @prop {string} label-highlight - Texto que aparece abaixo da label, em negrito
 * @prop {string} description - Texto do conteúdo
 * @prop {boolean} horizontal - Indica se o conteúdo dev aparecer ao lado da label, ao invés de abaixo
 *
 * @tag atlas-summary-item
 */
@customElement("atlas-summary-item")
export default class AtlasSummaryItem extends WithPopoverMixin(LitElement) {
    static styles = styles;

    @property({ type: String }) label: string;

    @property({ type: String, attribute: "label-highlight" }) labelHighlight: string;

    @property({ type: String }) description: string;

    @property({ type: Boolean }) horizontal: boolean;

    renderInfoIcon() {
        return when(
            this.hasPopover(),
            () => html`
                <atlas-icon-button
                    icon="info"
                    theme="primary"
                    size="2x"
                    popover-title=${this.popoverTitle}
                    popover-content=${this.popoverContent}
                ></atlas-icon-button>
            `
        );
    }

    renderHighLightLabel() {
        return when(this.labelHighlight, () => html`<span class="highlight">${this.labelHighlight}</span>`);
    }

    render() {
        const wrapperClass = {
            wrapper: true,
            horizontal: this.horizontal
        };

        return html`
            <div class=${classMap(wrapperClass)}>
                <dt class="label-wrapper">
                    <span class="label">
                        <span>${this.label}</span>
                        ${this.renderHighLightLabel()}
                    </span>
                    ${this.renderInfoIcon()}
                </dt>
                <dd class="description">${this.description}</dd>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-summary-item": AtlasSummaryItem;
    }
}
