import { html } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { classMap } from "lit/directives/class-map.js";

import { CHECK_ELEMENTS, INPUT_ELEMENTS, SELECT_ELEMENTS, getAllFormElements, getFormValues } from "@/helpers/form";

import { WithCollapseMixin, WithCollapseProps } from "@/internals/mixins/with-collapse-mixin";

import type AtlasCheckbox from "@/components/form/atlas-checkbox/atlas-checkbox";
import type AtlasRadio from "@/components/form/atlas-radio/atlas-radio";

import type FormElement from "@/components/form/form-element";

import AtlasElementGroup, { ElementGroupProps } from "@/components/layout/atlas-element-group/atlas-element-group";

import styles from "./atlas-filter-group.scss";
import "@/components/display/atlas-heading/atlas-heading";
import "@/components/layout/atlas-layout/atlas-layout";
import { WithPopoverMixin, WithPopoverProps } from "@/internals/mixins/with-popover-mixin";

const ComposedClass = WithPopoverMixin(WithCollapseMixin(AtlasElementGroup));

export type FilterGroupProps = WithCollapseProps &
    WithPopoverProps &
    ElementGroupProps & {
        "header": string;
        "has-select-all": boolean;
    };

/**
 * @dependency atlas-heading
 * 
 * @slot default - Slot padrão para definir os elementos do grupo de filtro
 *
 * @tag atlas-filter-group
 */
@customElement("atlas-filter-group")
export default class AtlasFilterGroup extends ComposedClass {
    static styles = styles;

    /** Define o texto que dará nome ao grupo */
    @property({ type: String }) header: string;

    /** Define se o grupo terá um checkbox para selecionar todos os filhos */
    @property({ type: Boolean, attribute: "has-select-all" }) hasSelectAll: boolean;

    @query(".select-all") private _selectAllCheckbox: AtlasCheckbox;

    /** @internal */
    public async getUpdateComplete() {
        await super.getUpdateComplete();
        await Promise.all(this.slottedElements.map((element) => element.updateComplete));
        return true;
    }

    /** @internal */
    public onChangeCheckedState() {
        super.onChangeCheckedState();

        if (!this.hasSelectAll) return;

        const checkElements = this.getAllChildrenCheckElements();

        const checkedElements = checkElements
            .filter((element: HTMLElement) => element.tagName === "ATLAS-CHECKBOX")
            .reduce((acc: number, element: AtlasCheckbox) => (element.checked ? acc + 1 : acc), 0);

        if (checkedElements === 0) {
            this._selectAllCheckbox.uncheck();
        } else if (checkedElements === checkElements.length) {
            this._selectAllCheckbox.check();
        } else {
            this._selectAllCheckbox.indeterminate = true;
        }
    }

    /**
     * Retorna os dados do grupo de filtro
     * @param {boolean} asFormData - Retorna os dados do grupo em FormData
     * @returns {object | FormData} - Os dados do grupo
     */
    public getFilterGroupData(asFormData?: boolean) {
        return getFormValues(this, asFormData);
    }

    /**
     * Reseta os elementos do grupo de filtro
     * @returns {Element[]} - Os elementos do grupo
     */
    public getFilterGroupElements() {
        return getAllFormElements(this);
    }

    /**
     * Retorna o número de filtros aplicados
     * @returns {number} - O número de filtros aplicados
     */
    public countFilters() {
        let filterCounter = 0;

        filterCounter += this.countFilteredCheckedFields();
        filterCounter += this.countFilteredSelectInputFields();

        return filterCounter;
    }

    /**
     * Reseta os dados do grupo de filtro
     */
    public resetFilterData() {
        this.getFilterGroupElements().forEach((formElement: FormElement) => {
            const tagName = formElement.tagName.toLowerCase();

            if (INPUT_ELEMENTS.includes(tagName) || SELECT_ELEMENTS.includes(tagName)) {
                formElement.value = formElement.defaultValue;
            }

            if (CHECK_ELEMENTS.includes(tagName)) {
                const checkElement = formElement as AtlasCheckbox | AtlasRadio;
                checkElement.toggleChecked(checkElement.default || false);
            }
        });
    }

    /**
     * Atualiza os dados do grupo de filtro com os dados do grupo atual
     * @param {FormElement[]} formElements - Array de elementos do formulário
     */
    public mergeFilterGroup(formElements: Array<FormElement>) {
        const elementMap = new Map(
            formElements.map((formElement) => {
                const tagName = formElement.tagName.toLowerCase();

                if (CHECK_ELEMENTS.includes(tagName)) {
                    return [`${formElement.name};${formElement.value}`, formElement];
                }

                return [formElement.name, formElement];
            })
        );

        this.getFilterGroupElements().forEach((formElement: FormElement) => {
            const nameAndValue = `${formElement.name};${formElement.value}`;
            const tagName = formElement.tagName.toLowerCase();

            if (!elementMap.has(formElement.name) && !elementMap.has(nameAndValue)) return;

            if (INPUT_ELEMENTS.includes(tagName) || SELECT_ELEMENTS.includes(tagName)) {
                formElement.value = elementMap.get(formElement.name).value || "";
            }

            if (CHECK_ELEMENTS.includes(tagName)) {
                const checkElementOrigin = elementMap.get(nameAndValue) as AtlasCheckbox | AtlasRadio;
                const checkElementDestination = formElement as AtlasCheckbox | AtlasRadio;

                checkElementDestination.toggleChecked(checkElementOrigin.checked);
            }
        });
    }

    private onSelectAllChange(event: Event) {
        const selectAllCheckbox = event.target as AtlasCheckbox;
        const childrenCheckboxes = this.getAllChildrenCheckElements();

        childrenCheckboxes.forEach((checkbox: AtlasCheckbox) => {
            checkbox.toggleChecked(selectAllCheckbox.checked);
        });
    }

    private countFilteredCheckedFields() {
        return this.getAllChildrenCheckElements()
            .filter((element: AtlasRadio | AtlasCheckbox) => !element.default)
            .reduce((acc: number, slotted: AtlasRadio | AtlasCheckbox) => (slotted.checked ? acc + 1 : acc), 0);
    }

    private countFilteredSelectInputFields() {
        const selectInputElements = [...this.getAllChildrenInputElements(), ...this.getAllChildrenSelectElements()];

        return selectInputElements.filter((element: FormElement) => element.defaultValue !== element.value).length;
    }

    private getFilterWidth() {
        const elementRect = this.shadowRoot.querySelector(".atlas-filter-group").getBoundingClientRect();

        return `width: ${elementRect.width}px`;
    }

    /** @internal */
    public renderValidationMessage() {
        return when(
            !this._valid,
            () => html`
                <atlas-text size="xs" theme="danger" class="invalid-feedback" style=${this.getFilterWidth()}>
                    ${this._errorMessage}
                </atlas-text>
            `
        );
    }

    private renderSelectAll() {
        return when(
            this.hasSelectAll,
            () => html`
                <atlas-checkbox @atlas-checkbox-change=${this.onSelectAllChange} class="select-all"
                    >Selecionar todos</atlas-checkbox
                >
            `
        );
    }

    /** @internal */
    public renderPopover() {
        return when(
            this.hasPopover(),
            () => html`
                <atlas-icon-button
                    icon="info"
                    theme="primary"
                    size="2x"
                    popover-title=${this.popoverTitle}
                    popover-content=${this.popoverContent}
                ></atlas-icon-button>
            `
        );
    }

    private renderHeader() {
        return when(
            !!this.header,
            () => html`
                <div class="atlas-filter-group-header">
                    <atlas-layout inline gap="2" alignment="center">
                        <atlas-heading size="h6">${this.header}</atlas-heading>
                        ${this.renderPopover()}
                    </atlas-layout>
                    ${when(this.collapsible, () => this.renderCollapseButton())}
                </div>
            `
        );
    }

    /** @internal */
    public render() {
        const elementGroupClass = {
            "atlas-filter-group": true,
            "is-invalid": !this._valid,
            "with-header": !!this.header
        };

        const layoutClass = {
            "atlas-filter-group-content": true,
            "atlas-layout": true,
            "inline": this.getIsInline(),
            "mobile": this._deviceController.isMobile,
            "fluid": this.fluid,
            [`align-${this.alignment}`]: !!this.alignment,
            [`justify-${this.justify}`]: !!this.justify,
            [`gap-${this.gap}`]: !!this.gap
        };

        return html`
            <div class=${classMap(elementGroupClass)}>
                ${this.renderHeader()}
                ${this.renderContentWithCollapse(html`
                    <div class="${classMap(layoutClass)}">
                        ${this.renderSelectAll()}
                        <slot @slotchange=${this.onSlotChange}></slot>
                    </div>
                `)}
            </div>
            ${this.renderValidationMessage()}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-filter-group": AtlasFilterGroup;
    }
}
