import { LitElement, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";
import { Theme } from "@/internals/theme";
import { WithBadgeMixin, type WithBadgeProps } from "@/internals/mixins/with-badge-mixin";

import styles from "./atlas-onboarding-step.scss";
import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-text/atlas-text";
import "@/components/layout/atlas-layout/atlas-layout";

const STATUS_MAP = {
    pending: {
        icon: "circle",
        text: "Pendente",
        theme: "secondary"
    },
    review: {
        icon: "clock",
        text: "Em análise",
        theme: "warning"
    },
    approved: {
        icon: "check-circle",
        text: "",
        theme: "success"
    },
    attention: {
        icon: "alert-circle",
        text: "Precisa de atenção",
        theme: "danger"
    }
};

export type OnboardingStepProps = WithBadgeProps & {
    "name": string;
    "label": string;
    "header": string;
    "status": "pending" | "review" | "approved" | "attention";
    "status-text": string;
    "status-theme": Theme;
    "illustration": string;
};

/**
 * @emit {CustomEvent} atlas-onboarding-step-change - Evento disparado ao mudar a situação do passo
 */
@customElement("atlas-onboarding-step")
export default class AtlasOnboardingStep extends WithBadgeMixin(LitElement) {
    static styles = styles;

    /** Nome do passo */
    @property({ type: String }) name: string;

    /** Label que aparece no stepper do onboarding */
    @property({ type: String }) label: string;

    /** Título do passo */
    @property({ type: String }) header: string;

    /** Situação do passo (Serve para definir o ícone que aparece ao lado da label além do texto e tema padrão da situação) */
    @property({ type: String }) status: "pending" | "review" | "approved" | "attention" = "pending";

    /** Texto de situação do passo (Por padrão cada situação mostra um texto diferente, esse atributo serve para personalizar o texto) */
    @property({ type: String, attribute: "status-text" }) statusText: string;

    /** Tema da situação do passo (Por padrão cada situação mostra um texto com sua cor respectiva, esse atributo serve para personalizar a cor do texto) */
    @property({ type: String, attribute: "status-theme" }) statusTheme: Theme;

    /** Ilustração que representa o passo do onboarding */
    @property({ type: String }) illustration: string;

    @state() private _visible = false;

    public constructor() {
        super();

        this.badgeTheme = this.badgeTheme || "primary";
    }

    /**
     * Especifica a visibilidade do componente
     * @param {boolean} visible - Define se o componente deve estar visível (true) ou oculto (false)
     */
    public setVisible(visible: boolean) {
        this._visible = visible;
    }

    /**
     * Retorna o ícone associado ao status atual do componente
     * @returns {string | undefined} - O ícone correspondente ao status atual, ou "undefined" se não houver um ícone associado
     */
    public getStatusIcon() {
        return STATUS_MAP[this.status]?.icon;
    }

    /**
     * Retorna o tema associado ao status atual do componente
     * @returns {string | undefined} - O tema correspondente ao status atual, ou "undefined" se não houver um tema associado
     */
    public getStatusTheme() {
        return this.statusTheme || STATUS_MAP[this.status]?.theme;
    }

    /** @internal */
    @Watch(["status", "illustration"])
    public onStatusChange() {
        emit(this, "atlas-onboarding-step-change");
    }

    protected renderStatusText() {
        const statusText = this.statusText || STATUS_MAP[this.status]?.text;
        const statusTheme = this.getStatusTheme();

        if (!statusText) return null;

        const stepStatusClass = {
            "status-text": true,
            [`color-${statusTheme}`]: !!statusTheme
        };

        return html`<span class=${classMap(stepStatusClass)}>${statusText}</span>`;
    }

    public render() {
        const stepClass = {
            "onboarding-step": true,
            "visible": this._visible
        };

        return html`
            <div class=${classMap(stepClass)}>
                <div class="step-title">
                    <atlas-layout inline gap="2">
                        <atlas-heading size="h5">
                            ${this.header}
                            <slot name="hotspot"></slot>
                        </atlas-heading>
                        ${this.renderBadge()}
                    </atlas-layout>
                    ${this.renderStatusText()}
                </div>
                <div class="step-content">
                    <slot></slot>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-onboarding-step": AtlasOnboardingStep;
    }
}
