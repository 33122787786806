import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import AtlasInput, { InputProps } from "@/components/form/atlas-input/atlas-input";
import styles from "./atlas-password-input.scss";

export type PasswordInputProps = InputProps & {
    "remove-visibility-icon": boolean;
};

/**
 * @extends atlas-input
 *
 * @tag atlas-password-input
 */
@customElement("atlas-password-input")
export default class AtlasPasswordInput extends AtlasInput {
    static styles = styles;

    /** Propriedade que define se o input irá remover o ícone que altera a visibilidade dos caracteres */
    @property({ type: Boolean, attribute: "remove-visibility-icon" }) removeVisibilityIcon: boolean = false;

    /** @internal */
    public connectedCallback(): void {
        super.connectedCallback?.();

        this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
        this.addEventListener("atlas-input-icon-click", this.togglePasswordVisibility);

        this.hasIconEvent = true;
        this.setInputToPassword();
    }

    /** @internal */
    public disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-input-icon-click", this.togglePasswordVisibility);
    }
    
    /** @override */
    protected renderIcon() {
        return when(!this.removeVisibilityIcon, () => super.renderIcon());
    }
    
    private togglePasswordVisibility() {
        if (this.type === "password") {
            this.setInputToText();
        } else {
            this.setInputToPassword();
        }
    }
    
    private setInputToPassword() {
        this.type = "password";
        this.icon = "eye-off";
    }
    
    private setInputToText() {
        this.type = "text";
        this.icon = "eye";
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-password-input": AtlasPasswordInput;
    }
}
