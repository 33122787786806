import { html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { live } from "lit/directives/live.js";
import { when } from "lit/directives/when.js";

import VanillaCalendar from "vanilla-calendar-pro";
import type { FormatDateString } from "vanilla-calendar-pro/types";

import Inputmask from "@/vendors/inputmask-utils";
import { autoUpdate, computePosition, flip, offset, shift } from "@floating-ui/dom";

import { Watch } from "@/decorators/watch";
import DeviceController from "@/controllers/device-controller";
import DateRangeValidator from "@/internals/validators/date-range-validator";

import type { InputSize, InputWidth } from "@/components/form/atlas-input/types";
import {
    formatDate,
    formatDateToYearMonthDay,
    getDateFromString,
    getTodayDate,
    parseDate
} from "@/internals/date-utils";
import { emit } from "@/internals/events";

import FormControl, { type FormControlProps } from "@/components/form/form-control";
import { WithTooltipMixin, type WithTooltipProps } from "@/internals/mixins/with-tooltip-mixin";
import styles from "./atlas-date-range.scss";
import "@/components/display/atlas-button/atlas-button";
import "@/components/display/atlas-icon/atlas-icon";

export type DateRangeProps = FormControlProps &
    WithTooltipProps & {
        "size": InputSize;
        "width": InputWidth;
        "readonly": boolean;
        "loading": boolean;
        "min-date": string;
        "max-date": string;
        "prevent-past-date": string;
        "prevent-later-date": string;
        "name-input-start": string;
        "name-input-end": string;
        "require-full-range": boolean;
    };

/**
 * @tag atlas-date-range
 */
@customElement("atlas-date-range")
export default class AtlasDateRange extends WithTooltipMixin(FormControl) {
    static styles = styles;

    /** O tamanho do input */
    @property({ type: String }) size: InputSize = "md";

    /** A largura do input */
    @property({ type: String }) width: InputWidth = "auto";

    /** Indica se o input é apenas para leitura */
    @property({ type: Boolean, reflect: true }) readonly = false;

    /** Indica se o input está em estado de loading */
    @property({ type: Boolean }) loading = false;

    /** A data mínima aceita pelo campo */
    @property({ type: String, attribute: "min-date" }) minDate: string;

    /** A data máxima aceita pelo campo */
    @property({ type: String, attribute: "max-date" }) maxDate: string;

    /** Indica se as datas anteriores ao dia atual serão desabilitadas */
    @property({ type: Boolean, reflect: true, attribute: "prevent-past-date" }) preventPastDate: boolean;

    /** Indica se as datas posteriores ao dia atual serão desabilitadas */
    @property({ type: Boolean, reflect: true, attribute: "prevent-later-date" }) preventLaterDate: boolean;

    /** Nome do input de data inicial (Necessário passar juntamente com o atributo `name-input-end`) */
    @property({ type: String, attribute: "name-input-start" }) nameInputStart: string;

    /** Nome do input de data final (Necessário passar juntamente com o atributo `name-input-start`) */
    @property({ type: String, attribute: "name-input-end" }) nameInputEnd: string;

    /** Indica se é necessário preencher tanto a data inicial e a data final */
    @property({ type: Boolean, attribute: "require-full-range" }) requireFullRange = false;

    @state() private _showCalendar = false;

    @state() private _startDate = "";

    @state() private _endDate = "";

    @state() private _focusedInput = "";

    @query(".form-control") private _formControl: HTMLElement;

    @query("input[data-range-type='start']") private _inputStart: HTMLInputElement;

    @query("input[data-range-type='end']") private _inputEnd: HTMLInputElement;

    @query(".calendar-wrapper") private _calendarWrapper: HTMLInputElement;

    private _maskInstanceStartDate: Inputmask.Instance;

    private _maskInstanceEndDate: Inputmask.Instance;

    private _calendarInstance: VanillaCalendar;

    private _fullDateDigits = 10;

    private _defaultPlaceholder = "___/___/____";

    private _floatingUiCleanup?: () => void;

    private _deviceController = new DeviceController(this);

    constructor() {
        super();

        this.updateCalendarPosition = this.updateCalendarPosition.bind(this);
        this.onChangeScreenType = this.onChangeScreenType.bind(this);
        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onInitAndUpdateCalendar = this.onInitAndUpdateCalendar.bind(this);
        this.onClickCalendarDay = this.onClickCalendarDay.bind(this);
    }

    connectedCallback(): void {
        super.connectedCallback?.();

        this._deviceController.setScreenChangeCallback(this.onChangeScreenType);

        this.updateComplete.then(() => {
            this.addValidator(new DateRangeValidator());

            this.createMask();
            this.createCalendar();
            this.registerFloatingUi();

            this.updateFocusMark();
        });
    }

    /**
     * Método que retorna o valor do campo de data inicial sem a máscara
     * @returns Valor do campo sem a máscara
     */
    public getStartDateUnmaskedValue() {
        return this._maskInstanceStartDate.unmaskedvalue();
    }

    /**
     * Método que retorna o valor do campo de data final sem a máscara
     * @returns Valor do campo sem a máscara
     */
    public getEndDateUnmaskedValue() {
        return this._maskInstanceEndDate.unmaskedvalue();
    }

    /**
     * Método que retorna o valor do campo de range, incluindo a data inicial e final
     * @returns Objeto contendo a data inicial e final
     */
    public getSelectedRange() {
        const valueParts = this.value?.split(",") || [];

        return {
            start: valueParts[0] || "",
            end: valueParts[1] || ""
        };
    }

    /**
     * Método que retorna a instância do calendário
     * @returns Instância do calendário
     */
    public getCalendarInstance() {
        return this._calendarInstance;
    }

    /**
     * Retorna a data máxima que pode ser selecionada no calendário
     * @returns Data máxima que pode ser selecionada
     */
    public getCalendarMaxDate() {
        if (this.maxDate) {
            return parseDate(this.maxDate);
        }

        const currentDate = getTodayDate();
        const currentYear = currentDate.getFullYear();

        const maxDate = new Date(
            this.preventLaterDate ? currentYear : currentYear + 80,
            currentDate.getMonth(),
            currentDate.getDate()
        );

        return maxDate;
    }

    /**
     * Retorna a data mínima que pode ser selecionada no calendário
     * @returns Data mínima que pode ser selecionada
     */
    public getCalendarMinDate() {
        if (this.minDate) {
            return parseDate(this.minDate);
        }

        if (this.preventPastDate) {
            return getTodayDate();
        }

        return null;
    }

    /**
     * Retorna o nome do campo de input de acordo com o tipo de range
     * @param rangeType - Tipo de range
     * @returns Nome do campo de input
     */
    public getInputName(rangeType: "start" | "end") {
        if (this.nameInputStart && this.nameInputEnd) {
            return rangeType === "start" ? this.nameInputStart : this.nameInputEnd;
        }

        const suffix = rangeType === "start" ? "Start" : "End";
        return this.name ? this.name + suffix : "";
    }

    /** @internal */
    @Watch(["minDate", "maxDate", "preventPastDate", "preventLaterDate"], true)
    public updateCalendarDateLimits() {
        this._calendarInstance.settings.range.min = formatDateToYearMonthDay(this.getCalendarMinDate());

        this._calendarInstance.settings.range.max = formatDateToYearMonthDay(this.getCalendarMaxDate());

        this._calendarInstance.update({ dates: true, month: true, year: true });

        if (!!this.value) this.reportValidity();
    }

    private getFocusedInput() {
        return this.shadowRoot.querySelector(`input[data-range-type="${this._focusedInput}"]`) as HTMLInputElement;
    }

    private setInputValue(value: string, rangeType: "start" | "end") {
        const currentRange = this.getSelectedRange();
        const newRange = { ...currentRange, [rangeType]: value };

        this.value = `${newRange.start},${newRange.end}`;
    }

    private setInputValueIfNotMobile(value: string, rangeType: "start" | "end") {
        if (!this._deviceController.isMobile) {
            this.setInputValue(value, rangeType);
        }
    }

    private setSelectedDate(value: string, rangeType: "start" | "end") {
        if (rangeType === "start") {
            this._startDate = value;
        } else {
            this._endDate = value;
        }
    }

    private updateCalendarSelectedDate(dontJumpToSelectedDate?: boolean) {
        this._calendarInstance.settings.selected.dates = [
            formatDateToYearMonthDay(this._startDate),
            formatDateToYearMonthDay(this._endDate)
        ];

        this._calendarInstance.update({ dates: true, month: !dontJumpToSelectedDate, year: !dontJumpToSelectedDate });
    }

    private createMask() {
        this._maskInstanceStartDate = Inputmask({ alias: "datepicker" }).mask(this._inputStart);
        this._maskInstanceEndDate = Inputmask({ alias: "datepicker" }).mask(this._inputEnd);
    }

    private createCalendar() {
        if (this._calendarInstance) this._calendarInstance.destroy();

        this._calendarInstance = new VanillaCalendar(this._calendarWrapper, {
            type: this._deviceController.isMobile ? "default" : "multiple",
            months: this._deviceController.isMobile ? 1 : 2,
            jumpMonths: 1,
            jumpToSelectedDate: true,
            date: {
                min: "" as FormatDateString,
                max: "" as FormatDateString
            },
            settings: {
                lang: "pt-BR",
                iso8601: false,
                range: {
                    min: formatDateToYearMonthDay(this.getCalendarMinDate()),
                    max: formatDateToYearMonthDay(this.getCalendarMaxDate()),
                    edgesOnly: true
                },
                visibility: {
                    daysOutside: false
                },
                selection: {
                    day: "multiple-ranged"
                }
            },
            actions: {
                clickDay: this.onClickCalendarDay,
                initCalendar: this.onInitAndUpdateCalendar,
                updateCalendar: this.onInitAndUpdateCalendar
            },
            DOMTemplates: {
                default: this.getCalendarDaysTemplate(),
                multiple: this.getCalendarDaysMultipleTemplate(),
                month: this.getCalendarMonthsTemplate(),
                year: this.getCalendarYearsTemplate()
            },
            CSSClasses: {
                arrowPrev: "atlas-icon ati-chevron-left",
                arrowNext: "atlas-icon ati-chevron-right"
            }
        });

        this._calendarInstance.init();
    }

    private getCalendarHeaderTemplate(renderArrows?: boolean) {
        return `
            <div class="vanilla-calendar-mobile-header">
                <span class="mobile-header-title">Selecione o período</span>
                <div class="mobile-header-controls">
                    <button class="control-button control-button-start">Data inicial</button>
                    <atlas-icon name="arrow-right" size="2x" theme="secondary"></atlas-icon>
                    <button class="control-button control-button-end">Data final</button>
                </div>
            </div>
            <div class="vanilla-calendar-header">
                ${renderArrows ? "<#ArrowPrev />" : ""}
                <div class="vanilla-calendar-header__content">
                    <#Month />
                    <#Year />
                </div>
                ${renderArrows ? "<#ArrowNext />" : ""}
            </div>
        `;
    }

    private getCalendarFooterTemplate() {
        return `
            <div class="vanilla-calendar-footer">
                <atlas-button type="ghost" description="Fechar" class="close-button"></atlas-button>
                <atlas-button type="ghost" description="Confirmar" class="confirm-button"></atlas-button>
            </div>
        `;
    }

    private getCalendarDaysTemplate() {
        return `
            ${this.getCalendarHeaderTemplate(true)}
            <div class="vanilla-calendar-wrapper">
                <#WeekNumbers />
                <div class="vanilla-calendar-content">
                    <#Week />
                    <#Days />
                </div>
            </div>
            ${this.getCalendarFooterTemplate()}
        `;
    }

    private getCalendarDaysMultipleTemplate() {
        return `
            <div class="vanilla-calendar-controls">
                <#ArrowPrev />
                <#ArrowNext />
            </div>
            <div class="vanilla-calendar-grid">
                <#Multiple>
                    <div class="vanilla-calendar-column">
                        ${this.getCalendarHeaderTemplate(false)}
                        <div class="vanilla-calendar-wrapper">
                            <#WeekNumbers />
                            <div class="vanilla-calendar-content">
                                <#Week />
                                <#Days />
                            </div>
                        </div>
                    </div>
                    <atlas-divider vertical></atlas-divider>
                <#/Multiple>
            </div>
            ${this.getCalendarFooterTemplate()}
        `;
    }

    private getCalendarMonthsTemplate() {
        return `
            ${this.getCalendarHeaderTemplate(false)}
            <div class="vanilla-calendar-wrapper">
                <div class="vanilla-calendar-content">
                    <#Months />
                </div>
            </div>
            ${this.getCalendarFooterTemplate()}
        `;
    }

    private getCalendarYearsTemplate() {
        return `
            ${this.getCalendarHeaderTemplate(true)}
            <div class="vanilla-calendar-wrapper">
                <div class="vanilla-calendar-content">
                    <#Years />
                </div>
            </div>
            ${this.getCalendarFooterTemplate()}
        `;
    }

    private onActionButtonClick() {
        if (this._showCalendar) {
            this.hideCalendar();
        } else {
            this.showCalendar();
        }
    }

    private onClickFormControl() {
        if (this._startDate.length === this._fullDateDigits && this._endDate.length !== this._fullDateDigits) {
            this._inputEnd.focus();
        } else {
            this._inputStart.focus();
        }
    }

    private async onChangeScreenType() {
        await this.updateComplete;

        this.createCalendar();
        this.registerFloatingUi();

        if (this._showCalendar && !this._deviceController.isMobile) {
            document.addEventListener("click", this.onDocumentClick);
        } else {
            document.removeEventListener("click", this.onDocumentClick);
        }
    }

    private async onDocumentClick(event: MouseEvent) {
        if (!this._showCalendar) return;

        const composedPath = event.composedPath();
        const isCalendarTarget = composedPath.includes(this._calendarWrapper);
        const isInputTarget = composedPath.includes(this._formControl);

        if (isCalendarTarget || isInputTarget) return;

        this.hideCalendar();
    }

    private onInitAndUpdateCalendar() {
        this.bindCalendarCustomButtons();
        this.bindCalendarDaysHover();
        this.bindCalendarControlButtons();

        this._calendarWrapper
            .querySelectorAll("button, atlas-button, [tabindex='0']")
            .forEach((el) => el.setAttribute("tabindex", "-1"));

        this.updateCalendarFocusControl();
    }

    private onClickCalendarDay(event: MouseEvent) {
        const target = event.target as HTMLElement;
        const selectedDate = target.getAttribute("data-calendar-day");
        const formattedDate = formatDate(selectedDate);

        this.setSelectedDate(formattedDate, this._focusedInput as "start" | "end");
        this.setInputValueIfNotMobile(formattedDate, this._focusedInput as "start" | "end");
        this.updateCalendarSelectedDate(true);

        setTimeout(() => {
            const parsedStartDate = parseDate(this._startDate);
            const parsedEndDate = parseDate(this._endDate);

            if (this._focusedInput === "start") {
                if (parsedEndDate && parsedStartDate > parsedEndDate) {
                    this.swapStartDateOnCalendarDayClick(formattedDate);
                }

                this._inputEnd.focus();
                return;
            }

            if (this._focusedInput === "end") {
                if (!parsedStartDate) {
                    this._inputStart.focus();
                    return;
                }

                if (parsedStartDate > parsedEndDate) {
                    this.swapStartDateOnCalendarDayClick(formattedDate);
                    this._inputEnd.focus();
                    return;
                }

                if (!this._deviceController.isMobile) {
                    this.hideCalendar();
                } else {
                    this._inputStart.focus();
                }
            }
        }, 0);
    }

    private swapStartDateOnCalendarDayClick(formattedDate: string) {
        this.setSelectedDate(formattedDate, "start");
        this.setInputValueIfNotMobile(formattedDate, "start");

        this.setSelectedDate("", "end");
        this.setInputValueIfNotMobile("", "end");

        this.updateCalendarSelectedDate(true);
    }

    private handleInputClick(event: MouseEvent) {
        event.stopPropagation();

        if (!this._deviceController.isMobile) {
            this.showCalendar();
        }
    }

    private handleFocus(event: FocusEvent) {
        const input = event.target as HTMLInputElement;

        this._focusedInput = input.getAttribute("data-range-type");
        this.updateFocusMark();

        if (!this._deviceController.isMobile) {
            this.showCalendar();
        }
    }

    private handleBlur() {
        if (!this._showCalendar) {
            this._focusedInput = "";
            this.updateFocusMark();
        }

        setTimeout(() => {
            if (document.activeElement !== this) {
                this.reportValidity();
                emit(this, "atlas-form-element-touch", { trackDisable: true });
            }
        }, 0);
    }

    private handleInput(event: InputEvent) {
        const input = event.target as HTMLInputElement;
        const rangeType = input.getAttribute("data-range-type") as "start" | "end";

        this.setInputValue(input.value, rangeType);

        this.setSelectedDate(Inputmask.isValid(input.value, { alias: "datepicker" }) ? input.value : "", rangeType);
        this.updateCalendarSelectedDate();

        if (input.value.length === this._fullDateDigits) {
            if (rangeType === "start" && this._inputEnd.value.length !== this._fullDateDigits) {
                this._inputEnd.focus();
            } else if (this._inputStart.value.length !== this._fullDateDigits) {
                this._inputStart.focus();
            }
        }
    }

    private handleInputKeydown(event: KeyboardEvent) {
        switch (event.key) {
            case "ArrowUp":
            case "ArrowDown":
                this.incrementOrDecrementCurrentDate(event);
                event.preventDefault();
                break;
            case "Backspace":
                this.changeFocusOnBackspace(event);
                break;
            case "Tab":
                this.hideCalendar();
                break;
        }
    }

    private incrementOrDecrementCurrentDate(event: KeyboardEvent) {
        const input = event.target as HTMLInputElement;
        const rangeType = input.getAttribute("data-range-type") as "start" | "end";

        const todayDate = getTodayDate();
        const factor = event.key === "ArrowUp" ? 1 : -1;
        let newDate = todayDate;

        if (!!input.value) {
            newDate = getDateFromString(input.value);
            newDate.setDate(newDate.getDate() + factor);
        }

        if (event.key === "ArrowUp" && newDate > this.getCalendarMaxDate()) return;
        if (event.key === "ArrowDown" && this.getCalendarMinDate() && newDate < this.getCalendarMinDate()) return;

        const formattedNewDate = formatDate(newDate);

        this.setInputValue(formattedNewDate, rangeType);
        this.setSelectedDate(formattedNewDate, rangeType);
        this.updateCalendarSelectedDate();
    }

    private changeFocusOnBackspace(event: KeyboardEvent) {
        const input = event.target as HTMLInputElement;
        const rangeType = input.getAttribute("data-range-type");

        if (input.value.length === 0 && rangeType === "end") {
            this._inputStart.focus();
        }
    }

    private showCalendar() {
        if (this._showCalendar) return;

        this._showCalendar = true;
        this._calendarWrapper.removeAttribute("aria-hidden");

        if (!this._focusedInput) {
            this._focusedInput = "start";
            this.updateFocusMark();
        }

        if (!this._deviceController.isMobile) {
            setTimeout(() => {
                document.addEventListener("click", this.onDocumentClick);
            }, 350);
        }
    }

    private hideCalendar() {
        this._showCalendar = false;
        this._calendarWrapper.setAttribute("aria-hidden", "true");
        this._focusedInput = "";
        this.updateFocusMark();

        document.removeEventListener("click", this.onDocumentClick);
    }

    private registerFloatingUi() {
        this._floatingUiCleanup?.();
        this._calendarWrapper.style.top = "";
        this._calendarWrapper.style.left = "";

        if (!this._deviceController.isMobile) {
            this._floatingUiCleanup = autoUpdate(this._formControl, this._calendarWrapper, this.updateCalendarPosition);
        }
    }

    private updateCalendarPosition() {
        computePosition(this._formControl, this._calendarWrapper, {
            placement: "bottom-start",
            strategy: "fixed",
            middleware: [offset({ mainAxis: 4, crossAxis: 0 }), flip(), shift({ padding: 8 })]
        }).then(({ x, y, placement }) => {
            this._calendarWrapper.setAttribute("data-popper-placement", placement);

            Object.assign(this._calendarWrapper.style, {
                left: `${x}px`,
                top: `${y}px`
            });
        });
    }

    private bindCalendarCustomButtons() {
        this._calendarWrapper.addEventListener("atlas-button-click", (event: CustomEvent) => {
            const button = event.target as HTMLElement;

            if (button.classList.contains("confirm-button")) {
                this.setInputValue(this._startDate, "start");
                this.setInputValue(this._endDate, "end");
            } else {
                this.setSelectedDate("", "start");
                this.setSelectedDate("", "end");
            }

            this.hideCalendar();
        });
    }

    private bindCalendarDaysHover() {
        this._calendarWrapper.addEventListener("mouseover", (event: MouseEvent) => {
            const input = this.getFocusedInput();
            const target = event.target as HTMLElement;

            const isDayDiv = target.classList.contains("vanilla-calendar-day");
            const dayButton = isDayDiv ? target.querySelector(".vanilla-calendar-day__btn") : target;

            const formattedDate = dayButton?.getAttribute("data-calendar-day");

            if (input && !this._deviceController.isMobile) {
                input.placeholder = formatDate(formattedDate) || this._defaultPlaceholder;
            }
        });

        this._calendarWrapper.addEventListener("mouseout", () => {
            const input = this.getFocusedInput();

            if (input && !this._deviceController.isMobile) {
                input.placeholder = this._defaultPlaceholder;
            }
        });
    }

    private bindCalendarControlButtons() {
        this._calendarWrapper.querySelectorAll(".control-button").forEach((button) => {
            button.addEventListener("click", () => {
                const rangeType = button.classList.contains("control-button-start") ? "start" : "end";

                this._focusedInput = rangeType;
                this.updateFocusMark();
            });
        });
    }

    private updateFocusMark() {
        const input = this.getFocusedInput();
        const focusMark = this.shadowRoot.querySelector(".focus-mark") as HTMLElement;

        if (input) {
            focusMark.style.left = `${input.offsetLeft}px`;
            focusMark.style.width = `${input.offsetWidth}px`;
            focusMark.style.display = "block";
        } else {
            focusMark.style.display = "none";
        }

        this.updateCalendarFocusControl();
    }

    private updateCalendarFocusControl() {
        this._calendarWrapper.querySelectorAll(".control-button").forEach((button) => {
            button.classList.toggle("active", button.classList.contains(`control-button-${this._focusedInput}`));

            if (button.classList.contains("control-button-start")) {
                button.innerHTML = this._startDate || "Data inicial";
            } else {
                button.innerHTML = this._endDate || "Data final";
            }
        });
    }

    private renderIcon() {
        return when(this.loading || !this._deviceController.isMobile, () => {
            return html`
                <atlas-icon name=${this.loading ? "loader" : "calendar"} size="2x" class="input-icon"></atlas-icon>
            `;
        });
    }

    private renderInput(rangeType: "start" | "end") {
        const ariaDescribedBy = this.helperText ? `${this.id}-helper` : "";
        const value = this.getSelectedRange()[rangeType] || "";

        const inputClass = {
            "form-control-input": true
        };

        return html`
            <input
                aria-describedby="${ariaDescribedBy}"
                id=${this.id + "-" + rangeType}
                name="${this.getInputName(rangeType)}"
                class=${classMap(inputClass)}
                type="text"
                inputmode="numeric"
                placeholder=${this._defaultPlaceholder}
                .value=${live(value)}
                ?disabled=${this.disabled}
                ?readonly=${this.readonly}
                ?required=${this.required}
                @focus=${this.handleFocus}
                @blur=${this.handleBlur}
                @click=${this.handleInputClick}
                @input=${this.handleInput}
                @keydown=${this.handleInputKeydown}
                autocomplete="off"
                data-range-type=${rangeType}
            />
        `;
    }

    private renderFormControl() {
        const formControlClass = {
            "form-control": true,
            "form-control-range": true,
            "form-control-sm": this.size === "sm",
            "form-control-lg": this.size === "lg",
            "has-icon": this.loading || !this._deviceController.isMobile,
            "has-action-button": this._deviceController.isMobile,
            [`is-${this._status}`]: this.getShowStatus()
        };

        return html`
            <div class=${classMap(formControlClass)} @click=${this.onClickFormControl}>
                ${this.renderInput("start")}
                <atlas-icon name="arrow-right" size="2x"></atlas-icon>
                ${this.renderInput("end")}
                <div class="focus-mark"></div>
            </div>
            ${this.renderIcon()} ${this.renderStatusMessage()}
        `;
    }

    private renderActionButton() {
        return when(
            this._deviceController.isMobile,
            () => html`
                <atlas-button
                    ?disabled=${this.disabled || this.loading}
                    icon="calendar"
                    size=${this.size}
                    theme="secondary"
                    type="outlined"
                    in-group-last
                    @atlas-button-click=${this.onActionButtonClick}
                ></atlas-button>
            `
        );
    }

    /**
     * @internal
     * @override
     */
    public render() {
        const inputGroupClass = {
            "input-group": true,
            "skeleton": this.skeletonLoading
        };

        const calendarClass = {
            "calendar-wrapper": true,
            "fade-and-scale": true,
            "show": this._showCalendar
        };

        const calendarBackdropClass = {
            "calendar-backdrop": true,
            "show": this._showCalendar
        };

        return html`
            ${this.renderLabel()}
            <div class="input-wrapper">
                <div class=${classMap(inputGroupClass)} data-atlas-tooltip="date-range-tooltip">
                    ${this.renderFormControl()}
                </div>
                ${this.renderActionButton()}
            </div>
            ${this.renderHelperText()} ${this.renderTooltip("date-range-tooltip")}
            ${when(this._deviceController.isMobile, () => html`<div class=${classMap(calendarBackdropClass)}></div>`)}
            <div class=${classMap(calendarClass)} aria-hidden="true"></div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-date-range": AtlasDateRange;
    }
}
