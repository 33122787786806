import { html } from "lit";
import { customElement, state, query, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";

import DeviceController from "@/controllers/device-controller";

import type AtlasInput from "../atlas-input/atlas-input";
import AtlasSelect, { type SelectProps } from "../atlas-select/atlas-select";
import { type SelectOption } from "../atlas-select/types";

import styles from "./atlas-multiselect.scss";

import "@/components/display/atlas-tag/atlas-tag";
import "@/components/form/atlas-input/atlas-input";
import "@/components/form/atlas-select/atlas-select-dropdown";
import "@/components/form/atlas-select-item/atlas-select-item";

export type MultiselectProps = SelectProps & {
    "enable-select-all": boolean;
};

/**
 * @dependency atlas-select-dropdown
 * @dependency atlas-tag
 * @dependency atlas-input
 * @dependency atlas-select-item
 *
 * @slot default - Slot padrão usado para definir as opções do multiselect
 *
 * @event {CustomEvent} atlas-multiselect-change - Evento disparado quando o valor do multiselect é alterado
 * @override {CustomEvent} atlas-select-change
 *
 * @tag atlas-multiselect
 */
@customElement("atlas-multiselect")
export default class AtlasMultiselect extends AtlasSelect {
    static styles = styles;

    /** Mostra a opção que permite selecionar todas as opções  */
    @property({ type: Boolean, attribute: "enable-select-all" }) enableSelectAll: boolean = false;

    @state() protected _selected: SelectOption[] = [];

    @state() private _tagMarginTop: string = "0";

    /** @override */
    @query(".atlas-multiselect-input")
    protected _selectInput: AtlasInput;

    private _deviceController = new DeviceController(this);

    /** @override */
    public getSelectedOptions(): SelectOption[] {
        return this._selected;
    }

    /** @override */
    protected onTagRemove() {
        this.value = "";
    }

    /** @override */
    protected onInputKeyDown(event: KeyboardEvent) {
        const escapeKeys = ["Tab", "Escape"];

        if (escapeKeys.includes(event.key)) return;

        this._selectDropdown.openDropdown();

        setTimeout(() => {
            if (event.key !== "Enter" && this._inputValue !== this._lastInputValue) {
                this._isTyping = true;
            }
        }, 0);
    }

    /** @override */
    protected onSelectDropdownInputKeyDown() {
        this._isTyping = true;
    }

    /** @internal */
    @Watch("value", true)
    public async onChangeValue() {
        await this.updateComplete;

        const selectedValues = this.getSelectedValues();
        this._selected = this._selectOptions.filter((option) => selectedValues.includes(`${option.value}`));

        this._showLoadingOnSearch = false;

        if (this._syncInputValueOnNextChange) {
            this.syncTextWithSelectedValue();
        }

        this._syncInputValueOnNextChange = true;

        emit(this, "atlas-multiselect-change");
    }

    /** @override */
    protected getVisibleOptions() {
        if ((!this.hasApiSearch() || this.searchOnce) && this._isTyping) {
            return this._selectOptions.filter((opt) =>
                opt.label.toUpperCase().includes(this._inputValue.toUpperCase())
            );
        }

        return this._selectOptions;
    }

    /** @override */
    protected getPlaceholder() {
        return this.placeholder || "Selecione as opções";
    }

    /** @override */
    protected syncTextWithSelectedValue() {
        this._inputValue = "";
    }

    /** @override */
    protected onSelectDropdownChange(event: CustomEvent) {
        const { option, removeOption } = event.detail;

        if (removeOption) {
            this.removeOption(option);
            return;
        }
        if (this.value) {
            this.selectOption(`${this.value},${option}`);
        } else {
            this.selectOption(`${option}`);
        }
    }

    private async calculateTagPosition() {
        await this._selectInput.updateComplete;

        const label = this._selectInput.shadowRoot.querySelector(".form-label");

        const labelPadding = 8;
        const borderAdjust = 1;
        const inputPadding = this._deviceController.isMobile ? 4 : 8;

        if (!label) {
            this._tagMarginTop = `${inputPadding + borderAdjust}px`;
            return;
        }

        const labelPosition = label.getBoundingClientRect();
        this._tagMarginTop = `${labelPosition.height + labelPadding + inputPadding + borderAdjust}px`;
    }

    private getTagText() {
        if (this._selected.length === 1) {
            return `${this._selected.length} opção selecionada`;
        }

        return `${this._selected.length} opções selecionadas`;
    }

    private removeOption(optionValue: string) {
        if (optionValue === "") {
            this.value = "";
            return;
        }

        this.value = this.getSelectedValues()
            .filter((value) => optionValue !== value)
            .join(",");
    }

    /** @override */
    protected renderTag() {
        if (this._selected.length === 0) return html``;

        this.calculateTagPosition();

        return html`
            <atlas-tag
                text=${this.getTagText()}
                ?disabled=${this.disabled}
                @atlas-tag-close-click=${this.onTagRemove}
                style="top: ${this._tagMarginTop}"
            ></atlas-tag>
        `;
    }

    /** @override */
    protected renderInput() {
        const inputClass = {
            "atlas-multiselect-input": true,
            "has-tag": this._selected.length > 0
        };

        const placeholder = this.getPlaceholder();

        return html`
            <atlas-input
                class="${classMap(inputClass)}"
                label=${this.label}
                size=${this.size}
                width=${this.width}
                placeholder=${placeholder}
                icon=${this._isDropdownOpen ? "chevron-up" : "chevron-down"}
                value=${this._inputValue}
                ?disabled=${this.disabled}
                ?loading=${this._showLoadingOnSearch && this.loading}
                ?hide-optional=${this.required || this.hideOptional}
                helper-text=${this.helperText}
                popover-title=${this.popoverTitle}
                popover-content=${this.popoverContent}
                tooltip=${this.tooltip}
                tooltip-placement=${this.tooltipPlacement}
                tooltip-trigger=${this.tooltipTrigger}
                data-atlas-dropdown="select-dropdown"
                @atlas-input-change=${this.onInputChange}
                @atlas-input-focus=${this.onInputFocus}
                @keydown=${this.onInputKeyDown}
                ignore-validations
                ?readonly=${!this.hasSearch() && !this.hasApiSearch()}
            >
                <slot name="helper-text" slot="helper-text"></slot>
            </atlas-input>
        `;
    }

    /** @override */
    protected renderSelectDropdown() {
        const placeholder = this.getPlaceholder();

        return html`
            <atlas-select-dropdown
                header=${this.label}
                ?loading=${this.loading || (this.hasApiSearch() && !this._hasDoneFirstSearch)}
                ?enable-new=${this.enableNew}
                ?enable-select-all=${this.enableSelectAll}
                new-item-prefix=${this.newItemPrefix}
                .options=${this.getVisibleOptions()}
                .selectedOptions=${this._selected}
                search-value=${this._inputValue}
                search-placeholder=${placeholder}
                empty-state-text=${this.emptyStateText}
                extra-keys=${this.extraKeys}
                .groups=${this.groups}
                ?is-typing=${this._isTyping}
                ?enable-search=${this.hasSearch() || this.hasApiSearch()}
                ?disabled=${this.disabled}
                multiselect
            ></atlas-select-dropdown>
        `;
    }

    /** @internal */
    render() {
        const multiselectClass = {
            "atlas-multiselect-container": true,
            "has-search": this.hasSearch() || this.hasApiSearch()
        };

        return html`
            <div class="${classMap(multiselectClass)}">${this.renderInput()} ${this.renderTag()}</div>
            ${this.renderSelectDropdown()}
            <slot @slotchange=${this.loadSlottedOptions}></slot>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-multiselect": AtlasMultiselect;
    }
}
