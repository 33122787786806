import { html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";

import FormControl, { FormControlProps } from "@/components/form/form-control";
import { Watch } from "@/decorators/watch";
import { debounce } from "@/internals/debounce";
import { emit } from "@/internals/events";
import { get } from "@/helpers/request";

import { WithTooltipMixin, WithTooltipProps } from "@/internals/mixins/with-tooltip-mixin";
import { InputSize, InputWidth } from "@/components/form/atlas-input/types";
import { SelectOption } from "./types";
import type AtlasSelectDropdown from "./atlas-select-dropdown";
import type AtlasInput from "@/components/form/atlas-input/atlas-input";
import type AtlasOption from "@/components/form/atlas-option/atlas-option";

import styles from "./atlas-select.scss";
import "./atlas-select-dropdown";
import "@/components/form/atlas-input/atlas-input";
import "@/components/form/atlas-select-item/atlas-select-item";

export type SelectProps = FormControlProps &
    WithTooltipProps & {
        "size": InputSize;
        "width": InputWidth;
        "placeholder": string;
        "loading": boolean;
        "new-item-prefix": string;
        "empty-state-text": string;
        "search-url": string;
        "search-once": boolean;
        "search-params": string;
        "value-key": string;
        "label-key": string;
        "search-on-render": boolean;
        "enable-new": boolean;
        "enable-search": boolean;
        "extra-keys": string;
    };

/**
 * @dependency atlas-select-dropdown
 * @dependency atlas-input
 * @dependency atlas-select-item
 *
 * @slot default - Slot padrão usado para definir as opções do select
 *
 * @event {CustomEvent} atlas-select-change - Evento disparado quando o valor do select é alterado
 *
 * @tag atlas-select
 */
@customElement("atlas-select")
export default class AtlasSelect extends WithTooltipMixin(FormControl) {
    static styles = styles;

    /** O tamanho do select */
    @property({ type: String }) size: InputSize = "md";

    /** A largura do select */
    @property({ type: String }) width: InputWidth = "auto";

    /** A mensagem que aparecerá quando o select está vazio */
    @property({ type: String }) placeholder: string;

    /** Indica se o select está em estado de loading */
    @property({ type: Boolean }) loading: boolean;

    /** Prefixo da mensagem que aparece na opção de adicionar um novo valor */
    @property({ type: String, attribute: "new-item-prefix" }) newItemPrefix: string = "Criar";

    /** Mensagem que é exibida no dropdown quando o select não possui opções */
    @property({ type: String, attribute: "empty-state-text" }) emptyStateText: string = "Sem escolhas para fazer";

    /** URL de onde os dados do select serão buscados (Isso habilita o input do select, permitindo digitar no campo para os resultados serem filtrados) */
    @property({ type: String, attribute: "search-url" }) searchUrl: string;

    /** Faz a pesquisa uma única vez no endpoint, as opções serão filtradas pelo frontend após a primeira busca */
    @property({ type: Boolean, attribute: "search-once" }) searchOnce: boolean;

    /** Parâmetros que serão enviados para o backend para serem utilizados como filtro, separados por ";" */
    @property({ type: String, attribute: "search-params" }) searchParams: string = "q";

    /** Chave do valor da opção que o backend vai retornar (O valor da opção quando ela for selecionada) */
    @property({ type: String, attribute: "value-key" }) valueKey: string;

    /** Chave do nome da opção que o backend vai retornar (O que será exibido nas opções do select) */
    @property({ type: String, attribute: "label-key" }) labelKey: string;

    /** Chave dos conteúdos extras que serão exibidos na opção do select, separados por ";" */
    @property({ type: String, attribute: "extra-keys" }) extraKeys: string;

    /** Faz a pesquisa ao renderizar o componente */
    @property({ type: Boolean, attribute: "search-on-render" }) searchOnRender: boolean;

    /** Indica se novos valores podem ser adicionados pelo próprio select */
    @property({ type: Boolean, attribute: "enable-new" }) enableNew: boolean;

    /** Habilita a busca no select */
    @property({ type: Boolean, attribute: "enable-search" }) enableSearch: boolean;

    /** Objeto contendo o nome e a chave dos grupos do select */
    @property({ type: Object }) groups: { [key: string]: string } = {};

    @state() protected _isDropdownOpen = false;

    @state() protected _syncInputValueOnNextChange = true;

    @state() protected _showLoadingOnSearch = true;

    @state() protected _inputValue = "";

    @state() protected _lastInputValue = "";

    @state() protected _selectOptions: SelectOption[] = [];

    @state() protected _selected: SelectOption[] = [];

    @state() protected _countNewOptions = 0;

    @state() protected _isTyping = false;

    @state() protected _hasDoneFirstSearch = false;

    @query(".atlas-select-input")
    protected _selectInput: AtlasInput;

    @query("atlas-select-dropdown")
    protected _selectDropdown: AtlasSelectDropdown;

    /** @internal */
    public async connectedCallback() {
        await super.connectedCallback?.();
        await this.updateComplete;

        this.onSelectDropdownChange = this.onSelectDropdownChange.bind(this);
        this.onCreateNewOption = this.onCreateNewOption.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputFocus = this.onInputFocus.bind(this);
        this.onInputKeyDown = this.onInputKeyDown.bind(this);
        this.onSelectDropdownInputKeyDown = this.onSelectDropdownInputKeyDown.bind(this);
        this.onDropdownOpen = this.onDropdownOpen.bind(this);
        this.onDropdownClose = this.onDropdownClose.bind(this);
        this.searchOptions = debounce(this.searchOptions.bind(this), 350);

        this.addEventListener("atlas-select-dropdown-change", this.onSelectDropdownChange);
        this.addEventListener("atlas-select-dropdown-create-new", this.onCreateNewOption);
        this.addEventListener("atlas-select-dropdown-search", this.onInputChange);
        this.addEventListener("atlas-select-dropdown-input-keydown", this.onSelectDropdownInputKeyDown);
        this.addEventListener("atlas-select-dropdown-opened", this.onDropdownOpen);
        this.addEventListener("atlas-select-dropdown-closed", this.onDropdownClose);
        this.addEventListener("atlas-form-element-value-change", this.syncInputValidation);

        if (this.searchOnRender) {
            this.searchOptions();
        }
    }

    /** @internal */
    public disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-select-dropdown-change", this.onSelectDropdownChange);
        this.removeEventListener("atlas-select-dropdown-create-new", this.onCreateNewOption);
        this.removeEventListener("atlas-select-dropdown-search", this.onInputChange);
        this.removeEventListener("atlas-select-dropdown-input-keydown", this.onSelectDropdownInputKeyDown);
        this.removeEventListener("atlas-select-dropdown-opened", this.onDropdownOpen);
        this.removeEventListener("atlas-select-dropdown-closed", this.onDropdownClose);
        this.removeEventListener("atlas-form-element-value-change", this.syncInputValidation);
    }

    /** @internal */
    @Watch(["_status", "_valid", "_statusMessage", "_showStatusMessage"])
    public async syncInputValidation() {
        await this.updateComplete;

        this._selectInput._status = this._status;
        this._selectInput._statusMessage = this._statusMessage;
        this._selectInput._valid = this._valid;
        this._selectInput._showStatusMessage = this._showStatusMessage;
    }

    /** @internal */
    @Watch("value", true)
    public async onChangeValue() {
        await this.updateComplete;

        const selectedValues = this.getSelectedValues();
        this._selected = this._selectOptions.filter((option) => selectedValues.includes(`${option.value}`));

        this._showLoadingOnSearch = false;

        if (this._syncInputValueOnNextChange) {
            this.syncTextWithSelectedValue();
        }

        this._syncInputValueOnNextChange = true;

        emit(this, "atlas-select-change");
    }

    /** 
     * Retorna a(s) opção(ões) selecionada(s)
     * @returns {SelectOption[] | SelectOption | object} - Um objeto ou um array de objetos contendo as opções selecionadas, ou um objeto vazio caso não haja opção selecionada
     */
    public getSelectedOptions(): SelectOption[] | SelectOption | object {
        return this._selected[0] || {};
    }

    /** 
     * Retorna o valor da opção selecionada (se multiselect, um array com os valores)
     * @returns {string[]} - Array com os valores das opções selecionadas
     */
    public getSelectedValues(): string[] {
        return `${this.value}`.split(",");
    }

    /** 
     * Aplica o foco no input do select 
     */
    public focus() {
        this._selectInput.focus();
        this._selectDropdown.openDropdown();
    }

    /** 
     * Remove o foco do input do select 
     */
    public blur() {
        this._selectInput.blur();
        this._selectDropdown.closeDropdown();
    }

    /** 
     * Define as opções do select
     * @param options - Array de objetos contendo as opções do select
     */
    public setOptions(options: SelectOption[]) {
        const selectedValues = this.getSelectedValues();

        this._selectOptions = options;
        this._selected = this._selectOptions.filter((option) => selectedValues.includes(`${option.value}`));

        this.syncTextWithSelectedValue();
    }

    /** 
     * Seleciona uma ou mais opções do select
     * @param option - Valor ou valores da(s) opção(ões) que deseja selecionar
     */
    public selectOption(option: string | string[] | SelectOption | SelectOption[]) {
        const optionValue: string[] = [];

        if (Array.isArray(option)) {
            option.forEach((opt) => {
                if (typeof opt !== "string") {
                    this.createOptionIfNeeded(opt);
                    optionValue.push(opt.value);
                } else {
                    optionValue.push(opt);
                }
            });
        } else if (typeof option !== "string") {
            this.createOptionIfNeeded(option);
            optionValue.push(option.value);
        } else {
            optionValue.push(option);
        }

        this._syncInputValueOnNextChange = true;
        this._isTyping = false;
        this.value = optionValue.join(",");
    }

    /** 
     * Realiza a busca das opções do select 
     */
    public searchOptions() {
        if (!this.hasApiSearch() || (this.searchOnce && this._hasDoneFirstSearch)) return;

        this.loading = true;
        this._hasDoneFirstSearch = true;

        const searchParams = this.searchParams?.split(";") || ["q"];
        const params = searchParams.reduce(
            (prev, cur) => ({ ...prev, [cur]: this.searchOnce ? "" : this._inputValue }),
            {}
        );

        const request = get(this.searchUrl, params);

        request.then((response) => {
            this._selectOptions = this.extractOptionsFromGroups(response);

            const selectedValues = this.getSelectedValues();

            if (this._selected.length > 0) {
                this._selected.forEach((selected) => this.createOptionIfNeeded(selected));
            } else if (this.value && selectedValues.length > 0) {
                this._selected = this._selectOptions.filter((option) => selectedValues.includes(`${option.value}`));
                this.syncTextWithSelectedValue();
            }

            setTimeout(() => {
                this.loading = false;
                this._showLoadingOnSearch = true;

                setTimeout(() => {
                    this._selectDropdown.updateDropdownPosition();
                    this._selectDropdown.setFirstFocus();
                }, 0);
            }, 350);
        });
    }

    protected hasApiSearch() {
        return !!this.searchUrl;
    }

    protected hasSearch() {
        return this.enableSearch || this.enableNew;
    }

    protected syncTextWithSelectedValue() {
        this._inputValue = this.value ? this._selected?.[0]?.label || "" : "";
    }

    protected onSelectDropdownChange(event: CustomEvent) {
        const { option } = event.detail;

        this.selectOption(`${option}`);
    }

    protected onCreateNewOption(event: CustomEvent) {
        const { optionLabel, group } = event.detail;

        this._countNewOptions += 1;

        this.selectOption([
            ...this._selected,
            {
                label: optionLabel,
                value: `${-this._countNewOptions}`,
                group
            }
        ]);
    }

    protected onInputChange(event: CustomEvent) {
        this._lastInputValue = this._inputValue;
        this._inputValue = event.detail;
        this.searchOptions();

        if (!this.hasApiSearch()) {
            this._selectDropdown.updateDropdownPosition();
        }
    }

    protected onInputFocus() {
        if (this.hasSearch() || this.hasApiSearch()) {
            this._selectInput._input.select();
        }
    }

    protected onInputKeyDown(event: KeyboardEvent) {
        const escapeKeys = ["Tab", "Escape"];

        if (escapeKeys.includes(event.key)) return;

        this._selectDropdown.openDropdown();

        setTimeout(() => {
            if (event.key !== "Enter" && this._inputValue !== this._lastInputValue) {
                this._syncInputValueOnNextChange = false;
                this.value = "";
                this._isTyping = true;
            }
        }, 0);
    }

    protected onSelectDropdownInputKeyDown() {
        this._syncInputValueOnNextChange = false;
        this.value = "";
        this._isTyping = true;
    }

    protected onDropdownOpen() {
        this._isDropdownOpen = true;

        if (!this._hasDoneFirstSearch) {
            this.searchOptions();
        }
    }

    protected onDropdownClose() {
        this._isDropdownOpen = false;
        this._isTyping = false;

        if (!this.value) {
            this.syncTextWithSelectedValue();
        }

        this.reportValidity();
    }

    protected loadSlottedOptions() {
        const optionsSlot = this.shadowRoot.querySelector("slot:not([name])") as HTMLSlotElement;
        const options = optionsSlot.assignedElements().map((option: AtlasOption) => ({
            label: option.label,
            value: option.value,
            disabled: option.disabled,
            group: option.group,
            customProperties: { ...option.dataset }
        }));

        this.setOptions(options);
    }

    protected hasOptionOnSelect(option: SelectOption) {
        return this._selectOptions.some((curOption) => `${curOption.value}` === `${option.value}`);
    }

    protected createOptionIfNeeded(option: SelectOption) {
        if (!this.hasOptionOnSelect(option)) {
            this._selectOptions = [option, ...this._selectOptions];
        }
    }

    protected getLabelForOption(option: { [key: string]: any }) {
        const labelKeys = this.labelKey.split(",");
        const firstKeyWithValue = labelKeys.find((labelKey) => !!option[labelKey]);

        return option[firstKeyWithValue];
    }

    protected extractOptionsFromGroups(response: any) {
        const groupsKeys = Object.keys(this.groups);
        const options: SelectOption[] = [];

        if (groupsKeys.length > 0) {
            groupsKeys.forEach((groupName: string) => {
                response[groupName].forEach((option: { [key: string]: any }) => {
                    options.push({
                        label: this.getLabelForOption(option),
                        value: option[this.valueKey],
                        selected: `${option[this.valueKey]}` === `${this.value}`,
                        group: groupName,
                        customProperties: { ...option }
                    });
                });
            });
        } else {
            response.forEach((option: { [key: string]: any }) => {
                options.push({
                    label: this.getLabelForOption(option),
                    value: option[this.valueKey],
                    selected: `${option[this.valueKey]}` === `${this.value}`,
                    customProperties: { ...option }
                });
            });
        }

        return options;
    }

    protected getVisibleOptions() {
        let options = this._selectOptions;

        if ((!this.hasApiSearch() || this.searchOnce) && this._isTyping) {
            options = options.filter((opt) => opt.label.toUpperCase().includes(this._inputValue.toUpperCase()));
        }

        return options;
    }

    protected getPlaceholder() {
        return this.value === "" && this._selected[0]
            ? this._selected[0]?.label || this.placeholder
            : this.placeholder;
    }

    protected renderInput() {
        const placeholder = this.getPlaceholder();

        return html`
            <atlas-input
                class="atlas-select-input"
                label=${this.label}
                size=${this.size}
                width=${this.width}
                placeholder=${placeholder}
                icon=${this._isDropdownOpen ? "chevron-up" : "chevron-down"}
                value=${this._inputValue}
                ?disabled=${this.disabled}
                ?loading=${this._showLoadingOnSearch && this.loading}
                ?hide-optional=${this.required || this.hideOptional}
                helper-text=${this.helperText}
                popover-title=${this.popoverTitle}
                popover-content=${this.popoverContent}
                tooltip=${this.tooltip}
                tooltip-placement=${this.tooltipPlacement}
                tooltip-trigger=${this.tooltipTrigger}
                data-atlas-dropdown="select-dropdown"
                @atlas-input-change=${this.onInputChange}
                @atlas-input-focus=${this.onInputFocus}
                @keydown=${this.onInputKeyDown}
                ignore-validations
                ?readonly=${!this.hasSearch() && !this.hasApiSearch()}
            >
                <slot name="helper-text" slot="helper-text"></slot>
            </atlas-input>
        `;
    }

    protected renderSelectDropdown() {
        const placeholder = this.getPlaceholder();

        return html`
            <atlas-select-dropdown
                header=${this.label}
                ?loading=${this.loading || (this.hasApiSearch() && !this._hasDoneFirstSearch)}
                ?enable-new=${this.enableNew}
                new-item-prefix=${this.newItemPrefix}
                .options=${this.getVisibleOptions()}
                .selectedOptions=${this._selected}
                search-value=${this._inputValue}
                search-placeholder=${placeholder}
                empty-state-text=${this.emptyStateText}
                extra-keys=${this.extraKeys}
                .groups=${this.groups}
                ?is-typing=${this._isTyping}
                ?enable-search=${this.hasSearch() || this.hasApiSearch()}
                ?disabled=${this.disabled}
            ></atlas-select-dropdown>
        `;
    }

    /** @internal */
    public render() {
        return html`
            ${this.renderInput()}
            ${this.renderSelectDropdown()}
            <slot @slotchange=${this.loadSlottedOptions}></slot>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-select": AtlasSelect;
    }
}
