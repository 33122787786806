import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import { type IconButtonSize } from "@/components/display/atlas-icon-button/types";

import { emit } from "@/internals/events";

import styles from "./atlas-visibility-icon-button.scss";

import "@/components/display/atlas-icon-button/atlas-icon-button";

export type VisibilityIconButtonProps = AtlasElementProps & {
    "icon-size": string;
    "is-data-visible": boolean;
    "is-icon-button": boolean;
    "block": boolean;
};

/**
 * @dependency atlas-icon-button
 *
 * @tag atlas-visibility-icon-button
 */
@customElement("atlas-visibility-icon-button")
export default class AtlasVisibilityIconButton extends AtlasElement {
    static styles = styles;

    /** Tamanho do ícone de visibilidade, caso for icon button */
    @property({ type: String, attribute: "icon-size" }) iconSize: IconButtonSize = "2x";

    /** Indica se os dados estão visíveis */
    @property({ type: Boolean, attribute: "is-data-visible", reflect: true }) isDataVisible: boolean = false;

    /** Indica se o ícone possui descrição ou somente ícone */
    @property({ type: Boolean, attribute: "is-icon-button" }) isIconButton: boolean = false;

    /** Indica se o botão irá ocupar a largura total*/
    @property({ type: Boolean }) block: boolean = false;

    private onClickVisibilityIcon() {
        this.isDataVisible = !this.isDataVisible;

        emit(this, "atlas-visibility-icon-click", {
            detail: {
                isDataVisible: this.isDataVisible
            }
        });
    }

    private getIconName() {
        return this.isDataVisible ? "eye-off" : "eye";
    }

    private getDescription() {
        return this.isDataVisible ? "Ocultar dados" : "Exibir dados";
    }

    private renderButton() {
        return html`
            <atlas-button
                type="ghost"
                theme="secondary"
                size="md"
                icon="${this.getIconName()}"
                description="${this.getDescription()}"
                ?block=${this.block}
                @atlas-button-click="${this.onClickVisibilityIcon}"
            ></atlas-button>
        `;
    }

    private renderIconButton() {
        return html`
            <atlas-icon-button
                icon="${this.getIconName()}"
                size="${this.iconSize}"
                theme="secondary"
                tooltip="${this.getDescription()}"
                theme-on-interactions
                hoverable
                @click="${this.onClickVisibilityIcon}"
            >
            </atlas-icon-button>
        `;
    }

    public render() {
        return when(
            this.isIconButton,
            () => this.renderIconButton(),
            () => this.renderButton()
        );
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-visibility-icon-button": AtlasVisibilityIconButton;
    }
}
