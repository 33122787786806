import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import { WithPopoverMixin, WithPopoverProps } from "@/internals/mixins/with-popover-mixin";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import "@/components/display/atlas-icon-button/atlas-icon-button";
import "@/components/display/atlas-text/atlas-text";
import "@/components/layout/atlas-layout/atlas-layout";

export type LabeledContentProps = AtlasElementProps &
    WithPopoverProps & {
        label: string;
    };

/**
 * @attr {string} label - Texto que aparece acima do conteúdo
 * @attr {string} popover-title - O título que será exibido no popover
 * @attr {string} popover-content - O conteúdo do popover
 *
 * @slot - Conteúdo do componente
 *
 * @tag atlas-labeled-content
 */
@customElement("atlas-labeled-content")
export default class AtlasLabeledContent extends WithPopoverMixin(AtlasElement) {
    @property({ type: String }) label: string;

    renderLabelIcon() {
        return when(
            this.hasPopover(),
            () => html`
                <atlas-icon-button
                    icon="info"
                    theme="primary"
                    size="2x"
                    popover-title=${this.popoverTitle}
                    popover-content=${this.popoverContent}
                ></atlas-icon-button>
            `
        );
    }

    render() {
        return html`
            <atlas-layout gap="2">
                <atlas-layout gap="2" alignment="center" inline mobile-inline>
                    <atlas-text>${this.label}</atlas-text>
                    ${this.renderLabelIcon()}
                </atlas-layout>
                <slot></slot>
            </atlas-layout>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-labeled-content": AtlasLabeledContent;
    }
}
