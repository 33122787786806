import {css} from "lit-element/lit-element.js";
export default css`:host([width=full]) {
  width: 100%;
}

:host([width=xs]) {
  width: 96px;
}

:host([width=sm]) {
  width: 152px;
}

:host([width=md]) {
  width: 264px;
}

:host([width=lg]) {
  width: 380px;
}

.atlas-multiselect-container {
  position: relative;
  cursor: pointer;
}
.atlas-multiselect-container.has-search {
  cursor: text;
}
.atlas-multiselect-container atlas-tag {
  position: absolute;
  left: 0.75rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2NvZGVidWlsZC9vdXRwdXQvc3JjMTQwNjkxNzc4MS9zcmMvcGFja2FnZXMvd2ViY29tcG9uZW50cy9zcmMvY29tcG9uZW50cy9mb3JtL2F0bGFzLW11bHRpc2VsZWN0Iiwic291cmNlcyI6WyIuLi8uLi8uLi9zdHlsZXMvY29tcG9uZW50cy9faW5wdXQtd2lkdGguc2NzcyIsImF0bGFzLW11bHRpc2VsZWN0LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBU0k7RUFDSSxPQVZPOzs7QUFTWDtFQUNJLE9BVk87OztBQVNYO0VBQ0ksT0FWTzs7O0FBU1g7RUFDSSxPQVZPOzs7QUFTWDtFQUNJLE9BVk87OztBQ0VmO0VBQ0k7RUFDQTs7QUFFQTtFQUNJOztBQUdKO0VBQ0k7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIiRpbnB1dC13aWR0aHM6IChcbiAgICBmdWxsOiAxMDAlLFxuICAgIHhzOiA5NnB4LFxuICAgIHNtOiAxNTJweCxcbiAgICBtZDogMjY0cHgsXG4gICAgbGc6IDM4MHB4XG4pO1xuXG5AZWFjaCAkd2lkdGgsICR2YWx1ZSBpbiAkaW5wdXQtd2lkdGhzIHtcbiAgICA6aG9zdChbd2lkdGg9XCIjeyR3aWR0aH1cIl0pIHtcbiAgICAgICAgd2lkdGg6ICR2YWx1ZTtcbiAgICB9XG59XG4iLCJAaW1wb3J0IFwiLi4vYXRsYXMtc2VsZWN0L2F0bGFzLXNlbGVjdFwiO1xuXG4uYXRsYXMtbXVsdGlzZWxlY3QtY29udGFpbmVyIHtcbiAgICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gICAgY3Vyc29yOiBwb2ludGVyO1xuXG4gICAgJi5oYXMtc2VhcmNoIHtcbiAgICAgICAgY3Vyc29yOiB0ZXh0O1xuICAgIH1cblxuICAgIGF0bGFzLXRhZyB7XG4gICAgICAgIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgICAgICAgbGVmdDogMC43NXJlbTtcbiAgICB9XG59XG4iXX0= */`;
